import React from 'react';
import DevelopImage from './assets/images/FlowDevelop.svg';

export function FlowDevelopComponent() {
  return (
    <section className='row odd'>
      <div className='sectionImage'>
        <img src={ DevelopImage } alt='Flow Delivery' />
      </div>
      <div className='sectionText'>
        <h3>Develop</h3>
        <p>Creating a product is merely the first step. Our entire team works together to continually refine it, verifying and rigorously testing everything to ensure both client and end-user satisfaction.</p>
      </div>
    </section>
  );
}

import React from 'react';
import StartImage from './assets/images/CTAImage.svg';

export function FlowStartComponent() {
  return (
    <section className='row odd'>
      <div className='sectionImage'>
        <img src={ StartImage } alt='Flow Start' />
      </div>
      <div className='sectionText'>
        <p>We're here</p>
        <h2>Along your side to Design, Develop and Deliver the next Digital Creativity</h2>
        <p>Turn your digital imagination to solution with our team of experts in business strategy, design and technology.</p>
      </div>
    </section>
  );
}

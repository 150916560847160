import React from 'react';
import Logo from './assets/images/Logo.svg';

export function HeaderComponent() {
  return (
    <header>
      <img className='logo' src={ Logo } alt='FlowFavour' />
    </header>
  );
}

import React from 'react';

export function FooterComponent() {
  return (
    <footer>
      <section className='row'>
        <h4>Flow Favour Co., Ltd.</h4><br/>
        <p>253 Asoke Building Floor 22,<br className='visible-xs' /> 253 Sukhumvit 21 Road, Khlong Toei Nuea, Wattana, Bangkok, 101100</p><br/>
        <p>Tel. +66 (0)2 108 0055 <span className='hidden-xs'>&nbsp;&nbsp;|&nbsp;&nbsp;</span><br className='visible-xs' /> Email. <a href='mailto:hello@flowfavour.co.th'>hello@flowfavour.co.th</a></p>
      </section>
      <section className='row ending'>
        <p>Copyright &copy;2020 FlowFavour Co., Ltd. <br className='visible-xs' /> All Rights Reserved.</p>
      </section>
    </footer>
  );
}

import React from 'react';
import FlowCTA from '../../components/content/flow-start';
import FlowDesign from '../../components/content/flow-design';
import FlowDevelop from '../../components/content/flow-develop';
import FlowDeliver from '../../components/content/flow-deliver';

export function HomePage () {
  return (
    <main>
      <FlowCTA />
      <FlowDesign />
      <FlowDevelop />
      <FlowDeliver />
    </main>
  );
}

import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize'
import TopBackground from './images/TopBG.svg';
import BottomBackground from './images/BottomBG.svg';

const GlobalStyle = createGlobalStyle`

  ${normalize}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    background-color: #f3f9fd;
  }

  h2, h3 {
    padding: 15px 0;
    font-size: 2em;
    line-height: 1.2em;
  }

  p {
    font-size: 1em;
    line-height: 1.5em;
  }

  img {
    max-width: 100%;
  }

  section {
    padding: 20px;
    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  header {
    width: 100vw;
    padding: 20px;
    .logo {
      max-width: 33%;
    }
  }

  footer {
    width: 100vw;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 105px 0 25px;
    background: url(${BottomBackground}) top center no-repeat;
    background-size: cover;
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  main {
    section {
      padding: 35px 20px;
    }
  }

  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${TopBackground}) top right no-repeat;
    background-size: contain;
  }

  .visible-xs {
    display: block;
  }

  .hidden-xs {
    display: none;
  }

  @media (min-width:760px) and (min-height:760px) {
    section {
      display: flex;
      flex-direction: row;
      &.odd {
        flex-direction: row-reverse;
        .sectionText {
          padding: 0 50px 0 0;
        }
      }
      .sectionText {
        padding: 0 0 0 50px;
      }
    }
    header {
      .logo {
        max-width: 150px;
      }
    }
    footer {
      section {
        flex-direction: column;
      }
    }
    main {
      section {
        padding: 35px 40px;
      }
    }
    .visible-xs {
      display: none;
    }
    .hidden-xs {
      display: inline-block;
    }
  }
  @media (min-width:1200px) {
    .App {
      background-size: 80%;
    }
    header, main {
      max-width: 1200px;
      .logo {
        max-width: 180px;
      }
    }
  }

`;

export default GlobalStyle;

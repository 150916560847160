import React from 'react';
import DeliverImage from './assets/images/FlowDeliver.svg';

export function FlowDeliverComponent() {
  return (
    <section className='row even'>
      <div className='sectionImage'>
        <img src={ DeliverImage } alt='Flow Delivery' />
      </div>
      <div className='sectionText'>
        <h3>Deliver</h3>
        <p>We oversee all technical aspects of product launch, then continue to remain dedicated to client success and future partnership by continuous monitoring to find potential areas of improvement as new technologies emerge.</p>
      </div>
    </section>
  );
}
